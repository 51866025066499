import * as React from "react";
import { Link } from "gatsby";
import "../styles/header.scss";

const Header = () => (
    <header>
        <div className={ "nav-content" }>
            <div className={ "nav-links" }>
                <Link to="/">Home</Link>
                <Link to="/blog">Blog</Link>
                <Link to="/social">Social</Link>
                <Link to="/about">About</Link>
            </div>
        </div>
    </header>
);

export default Header;
